import React, {
  CSSProperties,
  ChangeEvent,
  FC,
  ReactElement,
  TdHTMLAttributes,
  ThHTMLAttributes,
  cloneElement,
  useEffect,
  useState,
} from "react";
import SearchInput from "../SearchInput";
import Button from "../Button";
import EmptyState from "../EmptyState";
import { IconProps } from "../Icons";
import DateRange from "../DateRange";
import Filter, { IFilters, IProps } from "../Filter";
import { PropagateLoader } from "react-spinners";
import Pagination, { IOnClickData } from "../Pagination";
import { IQuery } from "../../interface/network";

interface TableProps {
  /**
   * The table header
   * @note wrap ReactElement inside ```th```
   */
  headers: Array<string | ReactElement<ThHTMLAttributes<unknown>>>;

  /**
   * table content
   * @note wrap ReactElement inside ```td```
   */
  data: Array<Array<string | ReactElement<TdHTMLAttributes<unknown>>>>;

  searchPlaceHolder?: string;

  emptyStateProps?: {
    title?: string;
    description?: string;
    icon?: IconProps["name"];
    buttonText?: string;
    buttonAction?: () => void;
    customButton?: JSX.Element;
  };

  hideSearch?: boolean;

  loading?: boolean;

  /**
   * total data
   */
  // totalPages: number;

  numberOfPages?: number;

  limit?: number;

  // totalResult: number;

  callBack?: (data?: IQuery) => any;

  currentPage?: number;

  hidePagination?: boolean;

  filterItems?: IFilters;
  selectedFilter?: Record<string, string>;
  selectedStartDate?: string;
  selectedEndDate?: string;
  showFilter?: boolean;
  showDateFilter?: boolean;

  // /**
  //  * Table title
  //  */
  // title?: string;

  // /**
  //  * Css style
  //  */
  // style?: CSSProperties;

  // /**
  //  * Css class name
  //  */
  // className?: string;

  // /**
  //  * Css class for table wrapper
  //  */
  // wrapperClassName?: string;

  // hidePagination?: boolean;

  // hideSearchBar?: boolean;

  // titleRhs?: unknown;

  // search?: boolean;
}
const Table: FC<TableProps> = ({
  headers,
  data,
  searchPlaceHolder,
  emptyStateProps,
  hideSearch,
  loading = false,
  hidePagination = false,
  showFilter = true,
  showDateFilter = true,
  ...props
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (search) {
        if (props.callBack) {
          props.callBack({
            page: 1,
            size: props?.limit?.toString(),
            search,
          });
        }
      }
    }, 800);

    return () => clearTimeout(debounceFn);
  }, [search]);

  const handlePageChange = (selectedItem: { selected: number }) => {
    if (props.callBack) {
      const selectedPage = selectedItem?.selected + 1;

      props.callBack({
        page: selectedPage?.toString(),
        size: props?.limit?.toString(),
        ...(search && { search }),
      });
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // const handlePaginationClick = (data: IOnClickData) => {
  //   console.log(data);
  // };

  return (
    <div>
      {/* {data.length > 0 && ( */}
      <div className="flex flex-row flex-wrap gap-5 justify-between items-center lg:h-[90px]">
        <div className="w-[100%] mt-5 lg:mt-0 lg:w-[40%]">
          {!hideSearch && (
            <SearchInput
              name="search"
              placeholder={
                searchPlaceHolder ?? "Search by name, account number or ID"
              }
              id="Search"
              onChange={handleSearchChange}
            />
          )}
        </div>

        <div className="flex gap-5">
          {showFilter && (
            <div>
              <Filter
                filters={props.filterItems}
                selected={props.selectedFilter}
                callBack={props.callBack}
              />
            </div>
          )}
          {showDateFilter && (
            <div>
              <DateRange
                callBack={props.callBack}
                defaultStart={props.selectedStartDate}
                defaultEnd={props.selectedEndDate}
              />
            </div>
          )}
        </div>
      </div>
      {/* )} */}
      {data?.length > 0 && !loading ? (
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray_500 font-medium uppercase bg-base_White border-b border-gray_200">
              <tr>
                {headers.length > 0 &&
                  headers.map((Elem, index) => {
                    return typeof Elem === "string" ? (
                      <th key={index}>{Elem}</th>
                    ) : (
                      cloneElement(Elem, { key: index })
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {data.map((elem, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b border-gray_200 text-gray_500"
                  >
                    {elem.map((item, _index) => {
                      return typeof item === "string" ? (
                        <td
                          className="px-6 py-4"
                          key={_index.toString().concat(index + "")}
                        >
                          {item}
                        </td>
                      ) : (
                        cloneElement(item, {
                          key: _index.toString().concat(index + ""),
                        })
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : loading ? (
        <div className="h-[200px] w-[100%] flex flex-row items-center justify-center">
          <PropagateLoader color="#003399" />
        </div>
      ) : (
        <EmptyState
          title={emptyStateProps?.title ?? ""}
          description={emptyStateProps?.description ?? ""}
          icon={emptyStateProps?.icon ?? "empty_transaction"}
          buttonText={emptyStateProps?.buttonText ?? ""}
          buttonAction={() =>
            emptyStateProps?.buttonAction
              ? emptyStateProps?.buttonAction()
              : null
          }
          customButton={emptyStateProps?.customButton}
        />
      )}
      <div className="mt-4">
        {data?.length > 0 && !hidePagination && (
          <Pagination
            onPageChange={handlePageChange}
            pageCount={props.numberOfPages ?? 0}
            initialPage={props.currentPage ?? 0}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
