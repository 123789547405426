import React, {
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Icon, IconProps } from "../components/Icons";
import {
  DASHBOARD,
  HELP_AND_SUPPORT,
  LOGIN,
  REPORTS,
  SETTINGS,
  TEAM,
  TRANSACTIONS,
  UPLOADS,
  WALLET,
} from "../routes/navigations";
import Text from "../components/Text";
import Button from "../components/Button";
import Notifications from "./components/notification";
// import { notifications } from "../utils/data";
import {
  clientPermissions,
  revokeAuthentication,
  selectAuthState,
  selectAuthenticationToken,
  selectUser,
  setUser,
} from "../redux/features/authSlice";
import AccessControl from "../components/AccessControl";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import SuspenseLoader from "../components/SuspenseLoader";
import api from "../network/api";
import {
  getBusinessInReviewStatus,
  getLoading,
  resetGlobalState,
  setBusinessStatus,
  setGlobalLoading,
} from "../redux/features/globalSlice";
import useToast from "../hooks/useToast";
import { capitalize, getInitials } from "../utils/utilMethods";
import { IError } from "../interface/error";
import VerifiedModal from "../Screens/dashboard/components/verifiedModal";
import { IGetNotification } from "../interface/notification";
import { resetPaymentState } from "../redux/features/paymentSlice";
import { IIdleTimer, useIdleTimer } from "react-idle-timer";
import InActiveModal from "../components/AuthModals/InActiveModal";
import ChangePasswordModal from "../Screens/settings/account/components/changePasswordModal";

const navs = [
  {
    label: "Overview",
    path: DASHBOARD,
    icon: "home",
    requiredPermission: [
      clientPermissions.CREATE_PAYMENT,
      clientPermissions.FUND_WALLET,
      clientPermissions.VIEW_WALLET,
      clientPermissions.VIEW_UPLOAD,
      clientPermissions.VIEW_TRANSACTION,
      clientPermissions.VIEW_VERIFICATION,
      clientPermissions.CREATE_VERIFICATION,
    ],
  },
  {
    label: "Wallet",
    path: WALLET,
    icon: "wallet",
    requiredPermission: [
      // clientPermissions.CREATE_PAYMENT,
      clientPermissions.FUND_WALLET,
      // clientPermissions.VIEW_TRANSACTION,
      clientPermissions.UPDATE_WALLET,
      clientPermissions.VIEW_WALLET,
    ],
  },
  {
    label: "Uploads",
    path: UPLOADS,
    icon: "uploads",
    requiredPermission: [
      clientPermissions.CREATE_UPLOAD,
      clientPermissions.VIEW_UPLOAD,
    ],
  },
  {
    label: "Transactions",
    path: TRANSACTIONS,
    icon: "transaction",
    requiredPermission: [
      clientPermissions.CREATE_UPLOAD,
      clientPermissions.VIEW_TRANSACTION,
    ],
  },
  {
    label: "Reports",
    path: REPORTS,
    icon: "report",
    requiredPermission: [clientPermissions.VIEW_REPORT],
  },
  {
    label: "Team",
    path: TEAM,
    icon: "team",
    requiredPermission: [
      clientPermissions.VIEW_TEAM,
      clientPermissions.CREATE_TEAM,
      clientPermissions.UPDATE_TEAM,
      clientPermissions.DELETE_TEAM,
    ],
  },
];

const otherNavs = [
  // {
  //   label: "Help & Support",
  //   path: HELP_AND_SUPPORT,
  //   icon: "help",
  //   requiredPermission: [
  //     clientPermissions.CREATE_SUPPORT,
  //     clientPermissions.VIEW_SUPPORT,
  //   ],
  // },
  {
    label: "Settings",
    path: SETTINGS,
    icon: "settings",
    requiredPermission: [
      clientPermissions.CREATE_SETTINGS,
      clientPermissions.UPDATE_SETTINGS,
      clientPermissions.VIEW_SETTINGS,
    ],
  },
];

const Main = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const authToken = useAppSelector(selectAuthenticationToken);
  const globalLoading = useAppSelector(getLoading);
  const businessInReviewStatus = useAppSelector(getBusinessInReviewStatus);

  const dispatch = useAppDispatch();
  const toast = useToast();
  const user = useAppSelector(selectUser);

  const timeout = Number(process.env.REACT_APP_INACTIVE_TIMEOUT) || 150000;

  const [openMenu, setOpenMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<
    Record<string, IGetNotification>
    // {
    //   id: string;
    //   message: string;
    //   date: string;
    //   read: boolean;
    // }
  >();
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [showVerifiedModal, setShowVerifiedModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState<number>(timeout / 1000);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    if (!user && authToken) {
      dispatch(setGlobalLoading(true));
      api
        .getUser()
        .then((res) => {
          dispatch(setUser(res));
        })
        .catch((err) =>
          toast.error(err.message ?? "Error getting user information")
        )
        .finally(() => dispatch(setGlobalLoading(false)));
    }
    if (user) {
      if (user.passwordChangeRequired) {
        togglePasswordModal();
        toast.success("You are required to changed your password");
      }
      if (businessInReviewStatus && user.company?.active === "ACCEPTED") {
        dispatch(setBusinessStatus(false));
        toggleVerifiedModal();
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && !notificationLoading) {
      getNotifications();
    }
  }, [notification, user]);

  const toggleInActiveModal = (): void => {
    setOpen((prev) => !prev);
  };

  const togglePasswordModal = () => setShowPasswordModal((prev) => !prev);

  const onActive = (event?: Event, idleTimer?: IIdleTimer): void => {
    // if (idleTimer?.isPrompted()) {
    setOpen(false);
    // }
  };

  const onPrompt = (): void => {
    setOpen(true);
  };

  const onIdle = (): void => {
    setOpen(false);
    logout();
  };

  const { getRemainingTime, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle: 1000 * 30,
    // onAction,
    onActive,
    onPrompt,
    onIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const handleStillHere = (): void => {
    activate();
  };

  const getNotifications = (asCallBack = false) => {
    if (!user) return;
    if (!asCallBack) setNotificationLoading(true);
    api
      .getNotification(user.company.id)
      .then((res) => {
        delete res.statusCode;
        setNotifications(res);
      })
      .catch((err) => {
        toast.error("Error getting notification");
      })
      .finally(() => setNotificationLoading(false));
  };

  const onPath = (to: string) => {
    if (path === to) {
      return true;
    }
    return false;
  };
  const toggleVerifiedModal = () => setShowVerifiedModal((prev) => !prev);

  const toggleNotification = () => setNotification((prev) => !prev);

  const toggleMenu = () => {
    const mobileNav = document.getElementById("mobile_nav");
    setOpenMenu((prev) => {
      if (!prev && mobileNav) {
        mobileNav.style.width = "100%";
        mobileNav.style.height = "100dvh";
      }
      if (prev && mobileNav) {
        mobileNav.style.width = "0";
        mobileNav.style.height = "0";
      }
      return !prev;
    });
  };
  const handleMenuClick = (e: MouseEvent<HTMLAnchorElement>, to: string) => {
    e.preventDefault();
    navigate(to);
    toggleMenu();
  };

  useEffect(() => {
    if (!authToken) {
      navigate(LOGIN, { replace: true });
    } else {
      setLoading(false);
    }
  }, [authToken]);

  const logout = async () => {
    try {
      dispatch(revokeAuthentication());
      dispatch(resetGlobalState());
      dispatch(resetPaymentState());
      await api.logout();

      navigate(LOGIN);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "Error logging out, please try again later");
    }
  };

  if (loading || globalLoading) return <SuspenseLoader />;
  return (
    <div className="relative font-Euclid">
      {/* Desktop */}
      <nav className="fixed top-0 left-0 transition-width duration-300 w-[280px] h-[100dvh] bg-base_White hidden lg:flex flex-col gap-2 border-r border-r-gray_200 px-4 pt-[32px] overflow-auto">
        <div>
          <img
            className=" w-auto"
            src="/logo2.png"
            style={{ height: 50 }}
            alt=""
          />
          {/* <img
            className=" w-auto"
            src="https://swiftmonie-dev2.s3.eu-north-1.amazonaws.com/Screenshot+2024-07-20+at+2.04.34%E2%80%AFPM.png"
            width={150}
            height={23}
            alt=""
            style={{
              width: 150,
              // height: 23,
            }}
          /> */}
        </div>
        <ul className="mt-[16px] flex flex-col gap-3 text-md text-gray_700">
          {navs.map((nav, index) => (
            <AccessControl
              key={nav.label + index}
              requiredPermission={nav.requiredPermission}
            >
              <li
                className={`py-8 px-[12px] transition-bg duration-300 flex flex-row gap-3 items-center ${onPath(nav.path) ? "text-primary_Blue_600 bg-primary_Blue_50 rounded-md" : ""} hover:bg-primary_Blue_50 rounded-md`}
              >
                <Icon
                  name={nav.icon as IconProps["name"]}
                  color={onPath(nav.path) ? "#003399" : ""}
                />
                <Link className="w-[100%]" to={nav.path}>
                  {nav.label}
                </Link>
              </li>
            </AccessControl>
          ))}
        </ul>
        <div className="flex flex-col flex-1 gap-3 justify-end mb-9 text-md text-gray_700">
          <ul>
            {otherNavs.map((nav, index) => (
              <li
                key={nav.label + index}
                className={`py-8 px-[12px] transition-bg duration-300 flex flex-row gap-3 items-center ${onPath(nav.path) ? "text-primary_Blue_600 bg-primary_Blue_50 rounded-md" : ""}`}
              >
                <Icon
                  name={nav.icon as IconProps["name"]}
                  color={onPath(nav.path) ? "#003399" : ""}
                />
                <Link className="w-[100%]" to={nav.path}>
                  {nav.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="border-t border-t-gray_200 pt-[24px] pb-0 pl-[8px] pr-[12px] flex flex-row items-center gap-3 relative  w-[100%]">
            <div className="rounded-full overflow-hidden min-h-[40px] max-h-[40px] max-w-[40px] flex flex-row items-center justify-center min-w-[40px] bg-primary_Blue_50 ">
              {!user?.profilePicture ? (
                <Text
                  text={getInitials(
                    `${user?.firstName ?? "-"} ${user?.lastName ?? "-"}`
                  )}
                  color="text-primary_Blue_600"
                />
              ) : (
                <img src={user?.profilePicture} alt="Avatar" />
              )}
              {user?.company?.active === "ACCEPTED" && (
                <div className="absolute z-[1000] bottom-0 left-9">
                  <Icon name="verified" />
                </div>
              )}
            </div>

            <div>
              <Text
                text={user?.company ? `${user.company.name}` : "-"}
                size={14}
                color="text-gray_700"
                classNames="max-w-[150px] truncate"
              />
              <Text
                text={
                  user?.firstName && user?.lastName
                    ? `${capitalize(user.firstName)} ${capitalize(user.lastName)}`
                    : "User"
                }
                size={14}
                color="text-gray_700"
              />
            </div>
            <div
              className="cursor-pointer flex flex-1 justify-end"
              onClick={logout}
            >
              <Icon name="logout" />
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile */}
      <nav
        id="mobile_nav"
        className={`fixed top-0 left-0 transition-width duration-300 w-[0px] h-[0px] bg-base_White flex lg:hidden flex-col gap-2 border-r border-r-gray_200 ${openMenu ? "px-4" : ""} pt-[32px] overflow-auto z-10 relative`}
      >
        {/* Hamburger */}
        <div
          onClick={toggleMenu}
          className={`lg:hidden cursor-pointer ml-auto mr-0 absolute right-18`}
        >
          <span
            className={`hamburger ${openMenu ? "transform translate-y-2 rotate-45" : ""}`}
          />
          <span
            className={`hamburger ${openMenu ? "opacity-0" : "opacity-100"}`}
          />
          <span
            className={`hamburger ${openMenu ? "transform -translate-y-2 -rotate-45" : ""}`}
          />
        </div>
        <div>
          <img
            className=" w-auto"
            src="/logo2.png"
            style={{ height: 50 }}
            alt=""
          />
        </div>
        <ul className="mt-[16px] flex flex-col gap-3 text-md text-gray_700">
          {navs.map((nav, index) => (
            <li
              key={nav.label + index}
              className={`py-8 px-[12px] transition-bg duration-300 flex flex-row gap-3 items-center ${onPath(nav.path) ? "text-primary_Blue_600 bg-primary_Blue_50 rounded-md" : ""}`}
            >
              <Icon
                name={nav.icon as IconProps["name"]}
                color={onPath(nav.path) ? "#003399" : ""}
              />
              <Link
                onClick={(e) => handleMenuClick(e, nav.path)}
                className="w-[100%]"
                to={nav.path}
              >
                {nav.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex flex-col flex-1 gap-3 justify-end mb-9 text-md text-gray_700">
          <ul>
            {otherNavs.map((nav, index) => (
              <li
                key={nav.label + index}
                className={`py-8 px-[12px] transition-bg duration-300 flex flex-row gap-3 items-center ${onPath(nav.path) ? "text-primary_Blue_600 bg-primary_Blue_50 rounded-md" : ""}`}
              >
                <Icon
                  name={nav.icon as IconProps["name"]}
                  color={onPath(nav.path) ? "#003399" : ""}
                />
                <Link
                  className="w-[100%]"
                  to={nav.path}
                  onClick={(e) => handleMenuClick(e, nav.path)}
                >
                  {nav.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="border-t border-t-gray_200 pt-[24px] pb-0 pl-[8px] pr-[12px] flex flex-row items-center gap-3 relative  w-[100%]">
            <div className="rounded-full overflow-hidden h-[40px] w-[40px] ">
              <img src={user?.profilePicture} alt="Avatar" />
              <div className="absolute z-[1000] bottom-0 left-9">
                <Icon name="verified" />
              </div>
            </div>

            <div>
              <Text
                text={user?.company ? `${user.company.name}` : "-"}
                size={14}
                color="text-gray_700"
              />
              <Text
                text={
                  user?.firstName && user?.lastName
                    ? `${capitalize(user.firstName)} ${capitalize(user.lastName)}`
                    : "User"
                }
                size={14}
                color="text-gray_700"
              />
            </div>
            <div
              className="cursor-pointer flex flex-1 justify-end"
              onClick={logout}
            >
              <Icon name="logout" />
            </div>
          </div>
        </div>
      </nav>

      <main className=" lg:ml-[280px] transition-all duration-300">
        <header
          className={`bg-white lg:px-[32px] font-Euclid border-b border-b-gray_100 ${openMenu ? "hidden" : ""}`}
        >
          <nav
            className="mx-auto flex-row flex max-w-7xl h-[70px] lg:items-center justify-between p-6 lg:px-8 "
            aria-label="Global"
          >
            <div className="flex items-center">
              <a href="#" className="-ml-1.5 lg:-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                {/* <img className=" w-auto" src="/logo.svg" alt="" /> */}
                {/* Hamburger */}
                <div
                  onClick={toggleMenu}
                  className={`lg:hidden cursor-pointer ml-0 mr-0`}
                >
                  <span
                    className={`hamburger ${openMenu ? "transform translate-y-2 rotate-45" : ""}`}
                  />
                  <span
                    className={`hamburger ${openMenu ? "opacity-0" : "opacity-100"}`}
                  />
                  <span
                    className={`hamburger ${openMenu ? "transform -translate-y-2 -rotate-45" : ""}`}
                  />
                </div>
              </a>
            </div>

            <div className="flex flex-1 justify-end items-center w-[100%] gap-5 lg:gap-9">
              <div
                className="flex cursor-pointer items-center justify-center h-[35px] w-[35px] rounded-md border border-[#D0D5DD] bg-base_White shadow-sm relative"
                onClick={toggleNotification}
              >
                <Icon name="bell" />

                {notifications &&
                  Object.values(notifications).some(
                    (notif) => notif.status !== "READ"
                  ) && (
                    <div className="absolute -top-[10px] -right-[5px] bg-error_600 h-[17px] w-[17px] rounded-xl flex justify-center items-center text-base_White text-[11px]">
                      {
                        Object.values(notifications).filter(
                          (notificationItem) =>
                            notificationItem.status !== "READ"
                        ).length
                      }
                    </div>
                  )}
              </div>
              {/* <Button
                variant="link"
                icon="help_support"
                label="Help & Support"
              /> */}
            </div>
          </nav>
        </header>
        <div className={`px-[32px] py-[32px]  ${openMenu ? "hidden" : ""}`}>
          <Outlet />
        </div>
      </main>
      {/* Notification drawer */}
      {notifications && (
        <Notifications
          toggle={toggleNotification}
          notifications={Object.values(notifications)}
          show={notification}
          loading={notificationLoading}
          callBack={getNotifications}
        />
      )}
      {/* modal */}

      {user && (
        <VerifiedModal
          show={showVerifiedModal}
          toggle={toggleVerifiedModal}
          setShow={setShowVerifiedModal}
          user={user}
        />
      )}
      <InActiveModal
        toggle={toggleInActiveModal}
        show={open}
        setShow={setOpen}
        onContinue={handleStillHere}
        onExit={logout}
        remaningTime={remaining}
      />
      <ChangePasswordModal
        show={showPasswordModal}
        toggle={togglePasswordModal}
        setShow={setShowPasswordModal}
      />
    </div>
  );
};

export default Main;
