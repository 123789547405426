import React, { FC } from "react";
import Card from "../Card";
import { Icon, IconProps } from "../Icons";
import Text from "../Text";
import Button from "../Button";
import useToast from "../../hooks/useToast";
import { handleError } from "../../utils/error";
import api from "../../network/api";
import { v4 as uuid } from "uuid";
import { useAppSelector } from "../../hooks/redux";
import { selectUser } from "../../redux/features/authSlice";
import { PropagateLoader } from "react-spinners";

export enum TileType {
  walletBalance = "walletBalance",
  transactionVolume = "transactionVolume",
  transactionCount = "transactionCount",
}

export const StatTilesComponent: FC<{
  buttonLabel?: string;
  disableButton?: boolean;
  icon: IconProps["name"];
  textOne: string;
  textTwo: string;
}> = ({ ...props }) => {
  return (
    <Card padding_x={"24px"} padding_y={"12px"} style={{ height: 180 }}>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center">
          <Icon name={props.icon} />
          <Text
            text={props.textOne}
            color="text-gray_900"
            size={14}
            classNames="font-medium"
          />
        </div>
        <Text
          text={props.textTwo}
          color="text-gray_900"
          size={36}
          classNames="font-semibold"
        />
        {props.buttonLabel && (
          <div>
            <Button
              variant="link"
              label="Activate your wallet"
              disabled={props.disableButton === true}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

const StatTiles: FC<{
  type: TileType;
  loading?: boolean;
  value?: string;
  activateWallet?: () => void;
  hide?: boolean;
}> = ({ type, loading, value, ...props }) => {
  const toast = useToast();
  const user = useAppSelector(selectUser);

  const activateWallet = async () => {
    try {
      if (props.activateWallet) props.activateWallet();
    } catch (error) {
      handleError(error, toast);
    }
  };
  if (props.hide) {
    return null;
  }
  if (type === TileType.transactionCount) {
    return (
      <Card padding_x={"24px"} padding_y={"12px"} style={{ height: 180 }}>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center">
            <Icon name="transaction_count" />
            <Text
              text="Transaction Count"
              color="text-gray_900"
              size={14}
              classNames="font-medium"
            />
          </div>

          <Text
            text={loading ? "loading..." : `${value}`}
            color="text-gray_900"
            size={36}
            classNames="font-semibold"
          />
        </div>
      </Card>
    );
  }
  if (type === TileType.transactionVolume) {
    return (
      <Card padding_x={"24px"} padding_y={"12px"} style={{ height: 180 }}>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center">
            <Icon name="transaction_volume" />
            <Text
              text="Transaction Volume"
              color="text-gray_900"
              size={14}
              classNames="font-medium"
            />
          </div>

          <Text
            text={loading ? "loading..." : `${value}`}
            color="text-gray_900"
            size={36}
            classNames="font-semibold"
          />
        </div>
      </Card>
    );
  }
  if (type === TileType.walletBalance) {
    return (
      <Card padding_x={"24px"} padding_y={"12px"} style={{ height: 180 }}>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center">
            <Icon name="dashboard_wallet" />
            <Text
              text="Wallet Balance"
              color="text-gray_900"
              size={14}
              classNames="font-medium"
            />
          </div>

          <Text
            text={loading ? "loading..." : `₦ ${value}`}
            color="text-gray_900"
            size={36}
            classNames="font-semibold"
          />

          {!user?.company.walletActivation && (
            <div>
              <Button
                onClick={activateWallet}
                variant="link"
                label="Activate your wallet"
                disabled={user?.company.active !== "ACCEPTED" || loading}
              />
            </div>
          )}
        </div>
      </Card>
    );
  }
  return null;
};

export default StatTiles;
