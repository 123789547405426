import React, { CSSProperties, FC } from "react";
import { Icon } from "../Icons";
import Text from "../Text";
import Button from "../Button";

interface IAlertProps {
  type: "info" | "error" | "success" | "primary";
  button?: JSX.Element;
  text: string;
  style?: CSSProperties;
}
const Alert: FC<IAlertProps> = ({ type, button, text, style }) => {
  if (type === "error") {
    return (
      <div
        style={style ? { ...style } : {}}
        className="p-4 flex gap-3 rounded-lg border border-error_500 bg-[#FFFBFA]"
      >
        <div className="flex-1">
          <Icon name="input_error" color="#ED3715" />
        </div>
        <div>
          <Text text={text} size={14} color="text-error_600" />

          {button && <div className="mt-3">{button}</div>}
        </div>
      </div>
    );
  }
  if (type === "primary") {
    return (
      <div
        style={style ? { ...style } : {}}
        className="p-4 flex gap-3 rounded-lg border border-primary_Blue_200 bg-primary_Blue_50"
      >
        <div className="flex-1">
          <Icon name="input_error" color="#003399" />
        </div>
        <div>
          <Text text={text} size={14} color="text-primary_Blue_600" />

          {button && <div className="mt-3">{button}</div>}
        </div>
      </div>
    );
  }
  if (type === "success") {
    return (
      <div
        style={style ? { ...style } : {}}
        className="p-4 flex gap-3 rounded-lg border border-success_300 bg-[#F6FEF9]"
      >
        <div className="flex-1">
          <Icon name="check" color="#00C076" />
        </div>
        <div>
          <Text text={text} size={14} color="text-success_600" />

          {button && <div className="mt-3">{button}</div>}
        </div>
      </div>
    );
  }
  return (
    <div
      style={style ? { ...style } : {}}
      className="p-4 flex gap-3 rounded-lg"
    >
      <div className="flex-1">
        <Icon name="input_error" color="#526277" />
      </div>

      <div>
        <Text text={text} size={14} color="text-gray_600" />

        {button && <div className="mt-3">{button}</div>}
      </div>
    </div>
  );
};

export default Alert;
