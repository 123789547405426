import React, { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import Text from "../components/Text";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getUploadDetails } from "../redux/features/paymentSlice";
import { DASHBOARD, UPLOADS } from "../routes/navigations";
import UploadPaymentDrawer from "../Screens/uploads/components/uploadPaymentsDrawer";
import UploadApprovalModal from "../components/UploadApprovalModal";
import { ApprovalActions } from "../interface/upload";
import {
  clientRoles,
  selectRoleAndPermission,
} from "../redux/features/authSlice";

const ReviewPaymentContainer = () => {
  const navigate = useNavigate();

  const uploadDetails = useAppSelector(getUploadDetails);
  const roleAndPermission = useAppSelector(selectRoleAndPermission);
  const { id } = useParams<{ id: string }>();

  const [showUploadDrawer, setShowUploadDrawer] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionType, setActionType] = useState<ApprovalActions | undefined>();

  const togglePaymentDrawer = () => setShowUploadDrawer((prev) => !prev);
  const toggleActionModal = () => setShowActionModal((prev) => !prev);

  const gotoUpload = async () => navigate(UPLOADS);

  return (
    <div className="h-screen flex flex-col overflow-y-auto">
      <header className="bg-white font-Euclid border-b border-b-gray_100">
        <nav
          className="mx-auto flex-col lg:flex-row flex max-w-7xl lg:items-center justify-between p-6 lg:px-8 "
          aria-label="Global"
        >
          <div className="lg:flex flex lg:flex-1">
            <Text
              text="Review payment details"
              color="text-[#000000]"
              size={18}
              classNames="font-medium"
            />
          </div>

          <div className="flex flex-1 justify-end w-[100%]">
            <Button
              variant="link"
              label=""
              icon="close"
              onClick={() => navigate(DASHBOARD)}
            />
          </div>
        </nav>
      </header>
      <main className="py-[28px] px-[40px] lg:py-[48px] lg:px-[80px]">
        <div className="lg:flex flex-row gap-[58px]">
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
      </main>

      {uploadDetails.length > 0 &&
        roleAndPermission?.role !== clientRoles.initiator && (
          <div className="min-h-[110px] flex justify-center mt-auto items-center border-t border-t-gray_200 bg-base_White">
            <div className="flex flex-row mx-auto gap-3">
              <Button
                label="Reject"
                classNames="min-w-[179px]"
                style={{ borderColor: "#FFAC9D", color: "#C82A0D" }}
                onClick={() => {
                  setActionType(ApprovalActions.REJECTED);
                  toggleActionModal();
                }}
              />
              <Button
                label="Approve"
                variant="primary"
                classNames="min-w-[179px]"
                onClick={() => {
                  setActionType(ApprovalActions.ACCEPTED);
                  toggleActionModal();
                }}
              />
            </div>
          </div>
        )}
      {/* drawer */}
      <UploadPaymentDrawer
        toggle={togglePaymentDrawer}
        show={showUploadDrawer}
        title="Initiate bulk payment"
        callBack={gotoUpload}
      />

      {/* modal */}
      {actionType && id && (
        <UploadApprovalModal
          toggle={toggleActionModal}
          setShow={setShowActionModal}
          show={showActionModal}
          type={actionType}
          batchId={id}
          callBack={gotoUpload}
        />
      )}
    </div>
  );
};

export default ReviewPaymentContainer;
