import { IPageable, ISort } from "./network";

export interface IUpload {
  title: string;
  batchId: string;
  fileContentType: string;
  fileName: string;
  base64File: string;
  settlementDate: string;
}

export interface IGetUploads {
  content: Array<{
    createdAt: string;
    updatedAt: string;
    id: number;
    title: string;
    batchId: string;
    filePath: string;
    userId: number;
    companyId: number;
    totalAmount: number;
    status: string;
    addedBy: string;
    currentApprovalStage: {
      id: number;
      name: string;
      description: string;
    };
    latestAction: any[];
  }>;
  pageable: IPageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  sort: ISort;
  first: boolean;
  empty: boolean;
}

export interface IGetUpload {
  content: Array<{
    createdAt: string;
    updatedAt: string;
    id: number;
    batchId: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
    amount: number;
    status: string;
    transDate: string;
    validated: boolean;
    verifiedName?: string;
  }>;
  pageable: IPageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  sort: ISort;
  first: boolean;
  empty: boolean;
}
export interface IResendUpload {
  title: string;
  batchId: string;
  fileContentType: string;
  fileName: string;
  base64File: string;
  settlementDate: string;
}

export enum ApprovalActions {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export interface IApproveRejectPayload {
  actionType: ApprovalActions;
  reason: string;
}

export type IUploadCommentsResponseData = {
  createdAt: string;
  updatedAt: string;
  id: number;
  approvalStageRole: {
    id: number;
    name: string;
    description: string;
  };
  userId: number;
  companyId: number;
  actionType: string;
  comment: string;
  addedBy: string;
};

export enum SuccessStatusEnum {
  SENT_FOR_PAYMENT = "SENT_FOR_PAYMENT",
  ACCEPTED = "ACCEPTED",
  READY_FOR_PAYMENT = "READY_FOR_PAYMENT",
  DONE = "DONE",
  // VERIFICATION_SUCCESSFUL = "VERIFICATION_SUCCESSFUL",
}

export enum FailedStatusEnum {
  REJECTED = "REJECTED",
  PAYMENT_EXCEPTION = "PAYMENT_EXCEPTION",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  VERIFICATION_CANCELED = "VERIFICATION_CANCELED",
  ERROR_SENDING_PAYMENT = "ERROR_SENDING_PAYMENT",
}
